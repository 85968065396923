import React, { useEffect, useState } from 'react';
import { db } from '../Services/Firebase/firebaseConfigOuvidoria'; // Ajuste o caminho
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Collapse,
    IconButton,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Grid,
    TextField,
    TablePagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import * as XLSX from 'xlsx';

const OuvidoriaRH = () => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState({});
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortColumn, setSortColumn] = useState('nome');
    const [statusTemp, setStatusTemp] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchDados = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'ouvidoriarh'));
                const dadosArray = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setDados(dadosArray);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDados();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = dados.filter(dado =>
        dado.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dado.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleToggle = (id) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [id]: !prevOpen[id],
        }));
    };

    const handleSort = (column) => {
        const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newDirection);
        setSortColumn(column);

        const sortedData = [...dados].sort((a, b) => {
            if (newDirection === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });

        setDados(sortedData);
    };

    const handleStatusChange = (id, newStatus) => {
        setStatusTemp((prevStatus) => ({
            ...prevStatus,
            [id]: newStatus,
        }));
        setSelectedId(id);
        setDialogOpen(true);
    };

    const handleConfirmUpdate = async () => {
        if (selectedId) {
            try {
                const docRef = doc(db, 'ouvidoriarh', selectedId);
                await updateDoc(docRef, { status: statusTemp[selectedId] });
                setDados((prevDados) =>
                    prevDados.map((dado) =>
                        dado.id === selectedId ? { ...dado, status: statusTemp[selectedId] } : dado
                    )
                );
                setDialogOpen(false);
                setSelectedId(null);
            } catch (error) {
                console.error('Erro ao atualizar status:', error);
            }
        }
    };

    const handleCancelUpdate = () => {
        setDialogOpen(false);
        setSelectedId(null);
    };

    const handleOpenModal = (docBase64) => {
        setSelectedDoc(docBase64);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDoc(null);
    };

    const exportToExcel = () => {
        const filteredData = dados.map(({ doc1, doc2, doc3, doc4, doc5, ...rest }) => rest);
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Dados');
        XLSX.writeFile(wb, 'dados_ouvidoria.xlsx');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Dados da Ouvidoria RH
            </Typography>
            <TextField
                label="Pesquisar"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ mb: 2 }}>
                Exportar para Excel
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('nome')} style={{ cursor: 'pointer' }} sx={{ fontWeight: 'bold' }}>
                                ID
                                <UnfoldMoreIcon sx={{ fontSize: 18, verticalAlign: 'middle' }} />
                            </TableCell>

                            <TableCell onClick={() => handleSort('nome')} style={{ cursor: 'pointer' }} sx={{ fontWeight: 'bold' }}>
                                Nome
                                <UnfoldMoreIcon sx={{ fontSize: 18, verticalAlign: 'middle' }} />
                            </TableCell>
                            <TableCell onClick={() => handleSort('email')} style={{ cursor: 'pointer' }} sx={{ fontWeight: 'bold' }}>
                                Email
                                <UnfoldMoreIcon sx={{ fontSize: 18, verticalAlign: 'middle' }} />
                            </TableCell>
                            <TableCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }} sx={{ fontWeight: 'bold' }}>
                                Status
                                <UnfoldMoreIcon sx={{ fontSize: 18, verticalAlign: 'middle' }} />
                            </TableCell>
                            <TableCell onClick={() => handleSort('criacao')} style={{ cursor: 'pointer' }} sx={{ fontWeight: 'bold' }}>
                                Data de Criação
                                <UnfoldMoreIcon sx={{ fontSize: 18, verticalAlign: 'middle' }} />
                            </TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((dado) => (
                            <React.Fragment key={dado.id}>
                                <TableRow>
                                    <TableCell>{dado.numeroRequisicao}</TableCell>
                                    <TableCell>{dado.nome}</TableCell>
                                    <TableCell>{dado.email}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={statusTemp[dado.id] || dado.status}
                                            onChange={(e) => handleStatusChange(dado.id, e.target.value)}
                                        >
                                            <MenuItem value="Em análise">Em análise</MenuItem>
                                            <MenuItem value="Concluído">Concluído</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>{dado.criacao}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleToggle(dado.id)}>
                                            {open[dado.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <Collapse in={open[dado.id]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Documentos
                                                        </Typography>
                                                        <ul>
                                                            <li>
                                                                <Button onClick={() => handleOpenModal(dado.doc1)}>
                                                                    Anexo 1
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button onClick={() => handleOpenModal(dado.doc2)}>
                                                                    Anexo 2
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <Button onClick={() => handleOpenModal(dado.doc3)}>
                                                                    Anexo 3
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Registro
                                                        </Typography>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            variant="outlined"
                                                            value={dado.registro}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Confirmar Atualização</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja realmente atualizar o status para "{statusTemp[selectedId]}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUpdate}>Cancelar</Button>
                    <Button onClick={handleConfirmUpdate} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle>Visualizar Documento</DialogTitle>
                <DialogContent>
                    {selectedDoc ? (
                        <img src={selectedDoc} alt="Anexo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    ) : (
                        <Typography>Nenhum documento disponível</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OuvidoriaRH;
