// firebaseConfig.js
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDvjHfhUiS3s9S6vGxxENNncBwp17hNNC0",
    authDomain: "ouvidoria-rh.firebaseapp.com",
    projectId: "ouvidoria-rh",
    storageBucket: "ouvidoria-rh.appspot.com",
    messagingSenderId: "726222442583",
    appId: "1:726222442583:web:3bbacd6358d93fe8cad312"
};

let app;
if (!getApps().length) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApps()[0]; // Usa o app já existente
}

const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth, signInWithEmailAndPassword };
