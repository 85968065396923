// RegisterPage.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Services/Firebase/firebaseConfigOuvidoria'; // Importe seu firebaseConfig
import { useNavigate } from 'react-router-dom'; // Importando useNavigate

function RegisterPage() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate(); // Hook para navegação

    const validateEmailDomain = (email) => {
        return email.endsWith('@memt.com.br') || email.endsWith('@minaseletric.com.br');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        if (!validateEmailDomain(email)) {
            setError('O email deve ser @memt.com.br ou @minaseletric.com.br');
            return;
        }

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setSuccess('Usuário criado com sucesso!');
            console.log('Registro bem-sucedido');
            navigate('/login'); // Redireciona para a página de login
        } catch (error) {
            setError('Falha ao criar o usuário. Verifique suas credenciais.');
            console.error('Erro ao criar usuário:', error.message);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, width: '100%' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Registrar Novo Usuário
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            variant="outlined"
                            label="Nome Completo"
                            fullWidth
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            label="Email"
                            type="email"
                            fullWidth
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            label="Senha"
                            type="password"
                            fullWidth
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <Typography color="error">{error}</Typography>}
                        {success && <Typography color="success">{success}</Typography>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Criar Conta
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default RegisterPage;
