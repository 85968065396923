import React, { useEffect, useState } from 'react';
import { Box, Typography, Link, Card, CardContent } from '@mui/material';
import Navigator from '../../Components/Navigator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

// Configuração do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

// Definição do tema
const theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
          width: 256,
        },
      },
    },
  },
});

const drawerWidth = 256;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [documentData, setDocumentData] = useState({ labels: [], counts: [] });
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [typeCounts, setTypeCounts] = useState({ Elogio: 0, 'Em análise': 0, Concluído: 0 });
  const [statusCounts, setStatusCounts] = useState({ 'Em análise': 0, Concluído: 0 });

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const fetchDocumentData = async () => {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'ouvidoriarh'));

      const data = [];
      let total = 0;
      const typeCount = { Elogio: 0 };
      const statusCount = { Concluído: 0 };

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const month = new Date(docData.criacao).toLocaleString('pt-BR', { month: 'long' });
        data.push(month);

        if (docData.tipo) {
          typeCount[docData.tipo] = (typeCount[docData.tipo] || 0) + 1;
        }

        if (docData.status) {
          statusCount[docData.status] = (statusCount[docData.status] || 0) + 1;
        }

        total++;
      });

      const monthCount = {};
      data.forEach((month) => {
        monthCount[month] = (monthCount[month] || 0) + 1;
      });

      const labels = Object.keys(monthCount);
      const counts = Object.values(monthCount);

      setDocumentData({ labels, counts });
      setTotalDocuments(total);
      setTypeCounts(typeCount);
      setStatusCounts(statusCount);
    };

    fetchDocumentData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: 'block', xs: 'none' } }}
        />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box component="main" sx={{ flex: 1, py: 4, px: 4, bgcolor: '#eaeff1' }}>
            <Typography variant="h4" gutterBottom>
              Gráficos de Dados
            </Typography>

            {/* Card de Total de Documentos */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6">
                  Total de Respostas: {totalDocuments}
                </Typography>
              </CardContent>
            </Card>

            {/* Cards para Contar Tipos e Status de Documentos */}
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', mb: 4 }}>
              {Object.entries(typeCounts).map(([type, count]) => (
                <Card key={type} sx={{ m: 1, width: 200 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {type}
                    </Typography>
                    <Typography variant="body1">
                      {count}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
              {Object.entries(statusCounts).map(([status, count]) => count > 0 && (
                <Card key={status} sx={{ m: 1, width: 200 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {status}
                    </Typography>
                    <Typography variant="body1">
                      {count}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>

            {/* Gráficos de Linha e Barra */}
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <Box sx={{ flex: 1, p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Gráfico de Barras - Respostas por Mês
                </Typography>
                <Bar
                  data={{
                    labels: documentData.labels || [],
                    datasets: [
                      {
                        label: 'Quantidade de Respostas',
                        data: documentData.counts || [],
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) => tooltipItem.raw,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ flex: 1, p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Gráfico de Linhas - Respostas por Mês
                </Typography>
                <Line
                  data={{
                    labels: documentData.labels || [],
                    datasets: [
                      {
                        label: 'Quantidade de Respostas',
                        data: documentData.counts || [],
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: true,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) => tooltipItem.raw,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://memt.com.br/">
        memt.com.br
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
