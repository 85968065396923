import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { auth } from './Pages/Services/Firebase/firebaseConfigOuvidoria';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from './Pages/Services/Firebase/firebaseConfigOuvidoria';
import { doc, getDoc } from 'firebase/firestore';
import './App.css';

// Import pages
import Login from './Pages/Login/Login';
import Registrar from './Pages/Registrar/Registrar';
import Home from './Pages/Home/Home';
import FormOuvidoria from './Pages/FormOuvidoria/FormOuvidoria';
import Introducao from './Pages/FormOuvidoria/Introducao'
import RespOuvidoria from './Pages/RespOuvidoria/RespOuvidoria';
import Painel from './Pages/Painel/Painel';
import Config from './Pages/Config/Config';

// Import components
import Navigator from './Components/Navigator';
import Header from './Components/Header';

// Definição do tema
const theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
          width: 256,
        },
      },
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [registrarEnabled, setRegistrarEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        // Buscando a configuração após autenticação
        const docRef = doc(db, 'configuracoes', 'suaConfiguracao');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRegistrarEnabled(docSnap.data().registrarEnabled);
          console.log('Registrar enabled:', docSnap.data().registrarEnabled); // Adicionado
        } else {
          console.log('Nenhuma configuração encontrada!');
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Ou um spinner de loading
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          {user && <Navigator />}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {user && <Header onDrawerToggle={() => {}} />}
            <Box component="main" sx={{ flex: 1, bgcolor: '#eaeff1' }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/registrar" element={registrarEnabled ? <Registrar /> : <Navigate to="/home" />} />
                <Route path="/painel" element={user ? <Painel /> : <Navigate to="/login" />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/form-ouvidoria" element={<FormOuvidoria />} />
                <Route path="/introducao" element={<Introducao />} />
                <Route path="/resp-ouvidoria" element={user ? <RespOuvidoria /> : <Navigate to="/login" />} />
                <Route path="/config" element={user ? <Config /> : <Navigate to="/login" />} />
              </Routes>
            </Box>
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
