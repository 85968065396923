import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Grid, Button, Container, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Services/Firebase/firebaseConfigOuvidoria'; // Certifique-se de importar corretamente sua instância do Firestore

function MainPage() {
    const [requisitionNumber, setRequisitionNumber] = useState('');
    const [status, setStatus] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const handleSearch = async () => {
        try {
            // Verificar se o valor da requisição é numérico
            const numericRequisitionNumber = Number(requisitionNumber);
            if (isNaN(numericRequisitionNumber)) {
                setStatus('Número de requisição inválido.');
                setOpenModal(true);
                return;
            }

            // Criar uma referência para a coleção 'ouvidoriarh'
            const colRef = collection(db, 'ouvidoriarh');

            // Criar uma consulta para encontrar documentos onde o campo 'numeroRequisicao' é igual ao valor buscado
            const q = query(colRef, where('numeroRequisicao', '==', numericRequisitionNumber));

            // Obter os documentos da consulta
            const querySnapshot = await getDocs(q);

            console.log('Número da requisição:', numericRequisitionNumber);
            console.log('Documentos encontrados:', querySnapshot.docs.length);

            if (!querySnapshot.empty) {
                // Iterar sobre todos os documentos encontrados
                querySnapshot.forEach((doc) => {
                    console.log('Documento:', doc.data()); // Log do conteúdo do documento
                    setStatus(doc.data().status || 'Status não disponível.'); // Supondo que o campo 'status' está presente no documento
                });
            } else {
                setStatus('Requisição não encontrada.');
            }
            setOpenModal(true); // Abrir o modal para exibir o status
        } catch (error) {
            console.error('Erro ao buscar status da requisição:', error);
            setStatus('Erro ao buscar status da requisição.');
            setOpenModal(true);
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <div>
            {/* AppBar */}
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Ouvidoria - Metrum
                    </Typography>
                    <Button component={Link} to="/" color="inherit">Home</Button>
                    <Button component={Link} to="/introducao" color="inherit">Ouvidoria</Button>
                    <Button component={Link} to="/login" variant="contained" color="success" sx={{ marginLeft: '25px' }}>Login</Button>
                </Toolbar>
            </AppBar>

            {/* Hero Section */}
            <Box
                sx={{
                    backgroundColor: '#e1f1fc',
                    padding: '50px 0',
                    textAlign: 'center',
                    minHeight: '400px',
                }}
            >
                <Container>
                    <Typography variant="h3" gutterBottom>
                        Bem-vindo a Ouvidoria Metrum
                    </Typography>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        Relate situações confidenciais de forma rápida e segura.
                    </Typography>
                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
                        <Grid item>
                            <Button component={Link} to="/introducao" variant="contained" color="primary" size="large">
                                Faça sua Denúncia
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Search Section */}
            <Box
                sx={{
                    padding: '40px 0',
                    backgroundColor: '#e1f1fc',
                }}
            >
                <Container>
                    <Typography variant="h5" gutterBottom>
                        Pesquisar por Número de Requisição
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        Digite o número da requisição abaixo para encontrar informações específicas.
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Número da Requisição"
                                value={requisitionNumber}
                                onChange={(e) => setRequisitionNumber(e.target.value)}
                                sx={{ marginBottom: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                sx={{ height: '100%' }}
                                onClick={handleSearch}
                            >
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* New Section */}
            <Box
                sx={{
                    padding: '60px 0',
                    backgroundColor: '#e1f1fc',
                }}
            >
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                Você sabe quais
                                <br />
                                situações podem
                                <br />
                                ser consideradas
                                <br />
                                relatos?
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                Conheça os tipos de relatos que podem ser feitos pelo Canal de Denúncias.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src="./image1.png"
                                alt="Tipos de Relatos"
                                style={{ width: '100%', borderRadius: '8px' }}
                                width='500px'
                                
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    padding: '60px 0',
                    backgroundColor: '#e1f1fc',
                }}
            >
                <Container>
                    <Grid container spacing={4} sx={{ marginTop: '40px' }}>
                        <Grid item xs={12} sm={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Por que denunciar?
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    A plataforma oferece um meio seguro e anônimo para relatar qualquer irregularidade ou comportamento antiético.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <img
                                    src="./image2.png"
                                    alt="Por que denunciar?"
                                    style={{ width: '100%', borderRadius: '8px' }}
                                    width='400px'
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Como funciona?
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    Através de um processo confidencial e seguro, sua denúncia será investigada com total imparcialidade.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <img
                                    src="./image3.png"
                                    alt="Como funciona?"
                                    style={{ width: '100%', borderRadius: '8px' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Modal for showing status */}
            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle>Status da Requisição</DialogTitle>
                <DialogContent>
                    <Typography>
                        {status === 'Requisição não encontrada.' ? status : `Olá, sua requisição está com Status: `}
                        <b>{status !== 'Requisição não encontrada.' ? status : ''}</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Footer */}
            <Box
                sx={{
                   
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '20px 0',
                    textAlign: 'center',
                }}
            >
                <Typography variant="body1">
                    © {new Date().getFullYear()} DEPTDI - Todos os direitos reservados.
                </Typography>
            </Box>
        </div>
    );
}

export default MainPage;
