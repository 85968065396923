// LoginPage.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import { auth, signInWithEmailAndPassword } from '../Services/Firebase/firebaseConfigOuvidoria';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Hook para navegação

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Limpa mensagens de erro anteriores

        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Redirecionar ou mostrar mensagem de sucesso
            console.log('Login bem-sucedido');
            navigate('/painel'); // Usando navigate para redirecionar
        } catch (error) {
            // Trate o erro aqui
            setError('Falha na autenticação. Verifique suas credenciais.');
            console.error('Erro ao fazer login:', error.message);
        }
    };

    const handleRegisterRedirect = () => {
        navigate('/registrar'); // Redireciona para a página de registro
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, width: '100%' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            type="email"
                            fullWidth
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            label="Senha"
                            type="password"
                            fullWidth
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <Typography color="error">{error}</Typography>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Entrar
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={handleRegisterRedirect} // Redireciona para registro
                        >
                            Registrar
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default LoginPage;
