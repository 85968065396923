import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';

// Define the categories for the navigation menu
const categories = [
  {
    id: 'Gerenciar',
    children: [
      { id: 'Dashboard', icon: <BarChartIcon />, route: '/painel' },
      { id: 'Respostas Ouvidoria', icon: <TableRowsIcon />, route: '/resp-ouvidoria' },
    
    ],
  },
  {
    id: 'Administrador',
    children: [

      { id: 'Configurações de Cadastro', icon: <ManageAccountsIcon />, route: '/config' },
    ],
  },
];

// Define the styles for the menu items and categories
const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

// Define the Navigator component
export default function Navigator(props) {
  const { ...other } = props;
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Drawer
      variant="permanent"
      {...other}
      sx={{ width: 256, flexShrink: 0 }}
    >
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Ouvidoria
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton onClick={() => handleNavigation(route)} sx={item}>
                  <ListItemIcon sx={{ color: '#fff' }}>{icon}</ListItemIcon>
                  <ListItemText sx={{ color: '#fff' }}>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
