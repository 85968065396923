import React, { useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, Container, Paper, Grid } from '@mui/material';
import { CheckCircle } from '@mui/icons-material'; // Ícone de check
import { useNavigate } from 'react-router-dom';

const OuvidoriaIntro = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleContinue = () => {
    if (isChecked) {
      navigate('/form-ouvidoria');
    } else {
      alert('Você precisa aceitar os termos para continuar.');
    }
  };

  return (
    <Box 
      sx={{
        background: 'linear-gradient(to bottom right, #f9f9f9, #e3f2fd)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem'
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={8} sx={{ p: 5, borderRadius: 4, backgroundColor: '#ffffff' }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1565c0', textAlign: 'center' }}>
            Canal de Denúncias da Metrum
          </Typography>

          <Typography variant="body1" paragraph sx={{ mb: 4, color: '#424242', textAlign: 'center' }}>
            O canal de denúncias da Metrum é uma ferramenta totalmente segura e sigilosa. As informações do denunciante serão recebidas e tratadas pela Contato Seguro, uma empresa externa, independente e especializada em Canais de Denúncias.
          </Typography>

          <Typography variant="body1" paragraph sx={{ mb: 3, color: '#424242' }}>
            Ao dar o aceite nos termos dispostos nesta página, você será redirecionado para o formulário correspondente à ação que deseja realizar pelo Canal — fazer uma denúncia, tirar uma dúvida ou dar uma sugestão.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1565c0', mt: 4 }}>
            Duas coisas que você precisa saber:
          </Typography>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>
                1. Você pode fazer sua denúncia de maneira anônima, ou seja, sem precisar se identificar;
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>
                2. Para acompanhar o andamento da sua denúncia, anote e guarde o número de protocolo que será gerado.
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1565c0' }}>
            Leia com atenção
          </Typography>

          <Typography variant="body1" paragraph sx={{ mb: 2, color: '#424242' }}>
            Para que sua denúncia seja concluída com sucesso, é importante colocar o máximo de informações possíveis, incluindo as provas que conseguir reunir. Assim, será possível validar os dados do seu relato e tomar as medidas necessárias.
          </Typography>

          <Typography variant="body1" paragraph sx={{ mb: 2, color: '#424242' }}>
            Seu relato poderá ser encerrado sem uma resposta conclusiva, caso não esteja de acordo com os deveres de:
          </Typography>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>Expor os fatos conforme a verdade;</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>Proceder com lealdade, gentileza e boa-fé;</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>Não agir de modo impulsivo;</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: '#2e7d32', mr: 2 }} />
              <Typography variant="body1" sx={{ color: '#424242' }}>
                Apresentar as informações que lhe foram solicitadas para o esclarecimento dos fatos.
              </Typography>
            </Grid>
          </Grid>

          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
            label="Li e estou de acordo com os termos acima."
            sx={{ mt: 3, color: '#1565c0' }}
          />

          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}
              disabled={!isChecked}
              sx={{ padding: '12px 28px', fontSize: '1rem', borderRadius: '30px', backgroundColor: '#1565c0' }}
            >
              Continuar
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default OuvidoriaIntro;
