import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Paper } from '@mui/material';
import { db } from '../Services/Firebase/firebaseConfigOuvidoria';
import { collection, addDoc, doc, getDoc, updateDoc, runTransaction } from 'firebase/firestore';

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

function FormOuvidoria() {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);
    const [base64Images, setBase64Images] = useState([]);
    const [numeroRequisicao, setNumeroRequisicao] = useState(0);
    const [showNumero, setShowNumero] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const imagesBase64 = [];
        for (const file of files) {
            if (file.type.startsWith('image/')) {
                const base64 = await toBase64(file);
                imagesBase64.push(base64);
            }
        }
        setBase64Images(imagesBase64);
        setFiles(files);
    };

    // Obtendo a data e hora atuais corretamente
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

   // Atualizando a função para retornar o novo número da requisição
const fetchNumeroRequisicao = async () => {
    const docRef = doc(db, 'config', 'numeroRequisicao'); // Documento para gerenciar o número da requisição
    try {
        const newNumero = await runTransaction(db, async (transaction) => {
            const docSnap = await transaction.get(docRef);
            if (!docSnap.exists()) {
                throw new Error("O documento de configuração do número de requisição não existe!");
            }
            const ultimoNumero = docSnap.data().ultimoNumero || 0;  // Valor padrão caso não exista
            const novoNumero = ultimoNumero + 1;
            
            transaction.update(docRef, { ultimoNumero: novoNumero });  // Atualizando no Firestore
            return novoNumero;  // Retorna o novo número
        });

        return newNumero;

    } catch (error) {
        console.error('Erro ao obter o número da requisição:', error);
        throw error;  // Propaga o erro para o handleSubmit lidar
    }
};

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        
        try {
            // Primeiro, buscamos e incrementamos o número da requisição
            const newNumeroRequisicao = await fetchNumeroRequisicao();
    
            // Depois, enviamos o formulário com o novo número da requisição
            await addDoc(collection(db, 'ouvidoriarh'), {
                numeroRequisicao: newNumeroRequisicao,  // Enviando o novo número atualizado
                criacao: formattedDate,
                tipo: type,
                nome: name || 'Anônimo',
                email: email || 'Anônimo',
                status: 'Em análise',
                registro: description,
                doc1: base64Images[0] || '',
                doc2: base64Images[1] || '',
                doc3: base64Images[2] || '',
                doc4: base64Images[3] || '',
            });
    
            console.log('Registro enviado com sucesso');
            setNumeroRequisicao(newNumeroRequisicao);  // Exibe o novo número ao usuário
            setShowNumero(true);
    
            // Opcional: Redirecionar para outra página ou limpar o formulário após a submissão
    
        } catch (error) {
            console.error('Erro ao enviar registro:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchNumeroRequisicao();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ paddingTop: 4 }}>
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom align="center">
                    Registro de Canal
                </Typography>

                <Typography variant="body1" paragraph>
                    Utilize este canal para Registrar, Elogios, Sugestões e Denúncias. O Canal é anônimo, você só se identifica se sentir confortável e houver necessidade.
                    <br />
                    <strong>*caso o usuário seja anônimo, não é possível dar um retorno personalizado ao solicitante. Garantimos que todos os dados pessoais fornecidos são estritamente confidenciais e não permitirão a exposição do colaborador correspondente.</strong>
                </Typography>

                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Tipo de Registro *</InputLabel>
                        <Select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required
                        >
                            <MenuItem value="Elogio">Elogio</MenuItem>
                            <MenuItem value="Sugestão">Sugestão</MenuItem>
                            <MenuItem value="Reclamação">Reclamação</MenuItem>
                            <MenuItem value="Denúncia">Denúncia</MenuItem>
                            <MenuItem value="Outros">Outros</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        variant="outlined"
                        label="Nome (Opcional)"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        label="E-mail (Opcional)"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        label="Descrição do Registro *"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />

                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ marginTop: 2 }}
                    >
                        Adicionar Imagens
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>

                    {files.length > 0 && (
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body1">Imagens Selecionadas:</Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 1 }}>
                                {Array.from(files).map((file, index) => (
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(file)}
                                        alt={`preview-${index}`}
                                        style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 4 }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Enviando...' : 'Enviar'}
                    </Button>

                    {showNumero && (
                        <Typography variant="body1" color="success.main" align="center" sx={{ marginTop: 2 }}>
                           Obrigado! Seu número de requisição é: {numeroRequisicao}
                        </Typography>
                    )}
                </form>
            </Paper>
        </Container>
    );
}

export default FormOuvidoria;
