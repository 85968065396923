import React, { useEffect, useState } from 'react';
import { db, auth } from '../Services/Firebase/firebaseConfigOuvidoria';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';

const Config = () => {
  const [configuracao, setConfiguracao] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); // Atualiza o estado com o usuário autenticado ou null
    });
    
    return () => unsubscribe(); // Limpa o listener quando o componente desmonta
  }, []);

  useEffect(() => {
    if (user) {
      fetchConfiguracao();
    } else {
      console.log("Usuário não autenticado.");
    }
  }, [user]);

  const fetchConfiguracao = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, 'configuracoes', 'suaConfiguracao');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setConfiguracao(docSnap.data());
      } else {
        console.log('Nenhuma configuração encontrada, criando nova...');
        await createConfiguracao(); // Cria a configuração se não existir
        fetchConfiguracao(); // Busca novamente após criar
      }
    } catch (error) {
      console.error('Erro ao buscar configuração:', error);
    } finally {
      setLoading(false);
    }
  };

  const createConfiguracao = async () => {
    const docRef = doc(db, 'configuracoes', 'suaConfiguracao');
    await setDoc(docRef, {
      tipo: 'registro',
      registrarEnabled: true // ou false conforme a necessidade
    });
  };

  const toggleConfiguracao = async () => {
    if (configuracao) {
      const updatedValor = !configuracao.registrarEnabled;
      await updateDoc(doc(db, 'configuracoes', 'suaConfiguracao'), {
        registrarEnabled: updatedValor,
      });
      setConfiguracao((prev) => ({ ...prev, registrarEnabled: updatedValor }));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Configurações
      </Typography>
      {configuracao ? (
        <Box
  sx={{
    p: 3,
    bgcolor: '#f5f5f5',
    borderRadius: 2,
    boxShadow: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  <Typography variant="h6" gutterBottom>
    Ativar ou Desativar Tela de Registro
  </Typography>
  <Typography variant="body1" sx={{ mb: 2 }}>
    Registro atualmente está: <strong>{configuracao.registrarEnabled ? 'Ativo' : 'Inativo'}</strong>
  </Typography>
  <Button
    variant="contained"
    color={configuracao.registrarEnabled ? 'warning' : 'primary'}
    onClick={toggleConfiguracao}
    sx={{ px: 4 }}
  >
    {configuracao.registrarEnabled ? 'Desativar' : 'Ativar'} Registro
  </Button>
</Box>
      ) : (
        <Typography>Nenhuma configuração disponível.</Typography>
      )}
    </Box>
  );
};

export default Config;
